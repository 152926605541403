var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticStyle: { height: "100%, width: 100%" },
      attrs: { id: _vm.componentId },
    },
    [
      _c(
        "b-modal",
        {
          attrs: {
            size: "md",
            title: _vm.labelTitle,
            "footer-class": "footerClass",
            "content-class": "shadow",
            "no-close-on-backdrop": "",
            scrollable: "",
          },
          on: {
            hidden: function ($event) {
              return _vm.$emit("update:show", false)
            },
          },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function ({ cancel }) {
                return [
                  _vm.canEdit() || !_vm.exists
                    ? _c(
                        "b-button",
                        {
                          attrs: { size: "sm", variant: "success" },
                          on: { click: _vm.ok },
                        },
                        [_vm._v(_vm._s(_vm.$i18n.t("button.ok")))]
                      )
                    : _vm._e(),
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "danger" },
                      on: {
                        click: function ($event) {
                          return cancel()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$i18n.t("button.cancel")))]
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.state.modalShow,
            callback: function ($$v) {
              _vm.$set(_vm.state, "modalShow", $$v)
            },
            expression: "state.modalShow",
          },
        },
        [
          _c(
            "b-alert",
            {
              attrs: { variant: "danger", dismissible: "" },
              on: { dismissed: _vm.dismissAlert },
              model: {
                value: _vm.errorShow,
                callback: function ($$v) {
                  _vm.errorShow = $$v
                },
                expression: "errorShow",
              },
            },
            [
              _c("font-awesome-icon", {
                attrs: { icon: ["fas", "triangle-exclamation"] },
              }),
              _vm._v("  " + _vm._s(_vm.alertMsg) + " "),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "container pl-0" },
            [
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { staticClass: "pr-0", attrs: { cols: "12" } },
                    [
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            label: _vm.$t("folder.field.parent_folder"),
                            "label-for": "parent",
                          },
                        },
                        [
                          _c(
                            "b-input-group",
                            [
                              _c("b-form-input", {
                                attrs: {
                                  id: "parent",
                                  type: "text",
                                  readonly: true,
                                },
                                model: {
                                  value: _vm.parent.name,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.parent, "name", $$v)
                                  },
                                  expression: "parent.name",
                                },
                              }),
                              !_vm.isNameReadOnly
                                ? _c(
                                    "b-input-group-append",
                                    [
                                      _c(
                                        "b-button",
                                        {
                                          attrs: {
                                            size: "sm",
                                            variant: "info",
                                          },
                                          on: { click: _vm.parentSelectOpen },
                                        },
                                        [_vm._v("Select")]
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                          _c(
                            "b-form-invalid-feedback",
                            {
                              staticClass: "alert-danger form-field-alert",
                              class: { "d-block": _vm.showParentError },
                            },
                            [
                              _c("font-awesome-icon", {
                                attrs: { icon: ["far", "circle-exclamation"] },
                              }),
                              _vm._v(
                                "  " +
                                  _vm._s(_vm.errors.first("parent.name")) +
                                  " "
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm.customFieldMap["parentFolder"] != null
                    ? _vm._l(
                        _vm.customFieldMap["parentFolder"],
                        function (field, index) {
                          return _c(
                            "b-col",
                            {
                              key: "parentFolder" + index,
                              staticClass: "pr-0",
                              attrs: { cols: "12" },
                            },
                            [
                              _c(
                                "b-form-group",
                                [
                                  field.type !== "Boolean"
                                    ? _c("template", { slot: "label" }, [
                                        _c("span", { staticClass: "mr-2" }, [
                                          _vm._v(_vm._s(field.displayName)),
                                        ]),
                                        field.description
                                          ? _c(
                                              "span",
                                              [
                                                _c("font-awesome-icon", {
                                                  style: {
                                                    color:
                                                      "var(--form-control-placeholder)",
                                                    fontSize: "0.9em",
                                                  },
                                                  attrs: {
                                                    id: `${_vm.componentId}_${field.name}`,
                                                    icon: [
                                                      "far",
                                                      "circle-question",
                                                    ],
                                                  },
                                                }),
                                                _c(
                                                  "b-popover",
                                                  {
                                                    attrs: {
                                                      target: `${_vm.componentId}_${field.name}`,
                                                      triggers: "hover",
                                                      placement: "top",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          field.description
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                      ])
                                    : _vm._e(),
                                  _c("CustomField", {
                                    attrs: {
                                      componentId: _vm.componentId,
                                      field: field,
                                      disabled:
                                        _vm.isReadOnly ||
                                        (_vm.exists &&
                                          !_vm.canEdit(_vm.permissionName, [
                                            field.name,
                                          ])),
                                    },
                                    model: {
                                      value: _vm.folder[field.name],
                                      callback: function ($$v) {
                                        _vm.$set(_vm.folder, field.name, $$v)
                                      },
                                      expression: "folder[field.name]",
                                    },
                                  }),
                                ],
                                2
                              ),
                            ],
                            1
                          )
                        }
                      )
                    : _vm._e(),
                  _c(
                    "b-col",
                    { staticClass: "pr-0", attrs: { cols: "12" } },
                    [
                      _c(
                        "b-form-group",
                        {
                          class: { "mb-0": _vm.showNameError },
                          attrs: {
                            label: _vm.$t("folder.field.name"),
                            "label-for": "name",
                          },
                        },
                        [
                          _c(
                            "b-input-group",
                            [
                              _c("b-form-input", {
                                ref: `${_vm.id}.name`,
                                attrs: {
                                  id: "name",
                                  type: "text",
                                  "data-vv-as": "Name",
                                  "data-vv-name": "folder.name",
                                  "data-vv-delay": "500",
                                  readonly: _vm.isNameReadOnly,
                                  state: _vm.fieldValidateUtil.stateValidate(
                                    _vm.isNameReadOnly,
                                    _vm.veeFields,
                                    _vm.errors,
                                    "folder.name"
                                  ),
                                  autofocus: "",
                                  trim: "",
                                },
                                nativeOn: {
                                  keydown: function ($event) {
                                    return _vm.keydownHandler.apply(
                                      null,
                                      arguments
                                    )
                                  },
                                },
                                model: {
                                  value: _vm.folder.name,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.folder, "name", $$v)
                                  },
                                  expression: "folder.name",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "b-form-invalid-feedback",
                            {
                              staticClass: "alert-danger form-field-alert",
                              class: { "d-block": _vm.showNameError },
                            },
                            [
                              _c("font-awesome-icon", {
                                attrs: { icon: ["far", "circle-exclamation"] },
                              }),
                              _vm._v(
                                "  " +
                                  _vm._s(_vm.errors.first("folder.name")) +
                                  " "
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm.customFieldMap["name"] != null
                    ? _vm._l(
                        _vm.customFieldMap["name"],
                        function (field, index) {
                          return _c(
                            "b-col",
                            {
                              key: "name" + index,
                              staticClass: "pr-0",
                              attrs: { cols: "12" },
                            },
                            [
                              _c(
                                "b-form-group",
                                [
                                  field.type !== "Boolean"
                                    ? _c("template", { slot: "label" }, [
                                        _c("span", { staticClass: "mr-2" }, [
                                          _vm._v(_vm._s(field.displayName)),
                                        ]),
                                        field.description
                                          ? _c(
                                              "span",
                                              [
                                                _c("font-awesome-icon", {
                                                  style: {
                                                    color:
                                                      "var(--form-control-placeholder)",
                                                    fontSize: "0.9em",
                                                  },
                                                  attrs: {
                                                    id: `${_vm.componentId}_${field.name}`,
                                                    icon: [
                                                      "far",
                                                      "circle-question",
                                                    ],
                                                  },
                                                }),
                                                _c(
                                                  "b-popover",
                                                  {
                                                    attrs: {
                                                      target: `${_vm.componentId}_${field.name}`,
                                                      triggers: "hover",
                                                      placement: "top",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          field.description
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                      ])
                                    : _vm._e(),
                                  _c("CustomField", {
                                    attrs: {
                                      componentId: _vm.componentId,
                                      field: field,
                                      disabled:
                                        _vm.isReadOnly ||
                                        (_vm.exists &&
                                          !_vm.canEdit(_vm.permissionName, [
                                            field.name,
                                          ])),
                                    },
                                    model: {
                                      value: _vm.folder[field.name],
                                      callback: function ($$v) {
                                        _vm.$set(_vm.folder, field.name, $$v)
                                      },
                                      expression: "folder[field.name]",
                                    },
                                  }),
                                ],
                                2
                              ),
                            ],
                            1
                          )
                        }
                      )
                    : _vm._e(),
                  _vm.customFieldMap["default"] != null
                    ? _vm._l(
                        _vm.customFieldMap["default"],
                        function (field, index) {
                          return _c(
                            "b-col",
                            {
                              key: index,
                              staticClass: "pr-0",
                              attrs: { cols: "12" },
                            },
                            [
                              _c(
                                "b-form-group",
                                [
                                  field.type !== "Boolean"
                                    ? _c("template", { slot: "label" }, [
                                        _c("span", { staticClass: "mr-2" }, [
                                          _vm._v(_vm._s(field.displayName)),
                                        ]),
                                        field.description
                                          ? _c(
                                              "span",
                                              [
                                                _c("font-awesome-icon", {
                                                  style: {
                                                    color:
                                                      "var(--form-control-placeholder)",
                                                    fontSize: "0.9em",
                                                  },
                                                  attrs: {
                                                    id: `${_vm.componentId}_${field.name}`,
                                                    icon: [
                                                      "far",
                                                      "circle-question",
                                                    ],
                                                  },
                                                }),
                                                _c(
                                                  "b-popover",
                                                  {
                                                    attrs: {
                                                      target: `${_vm.componentId}_${field.name}`,
                                                      triggers: "hover",
                                                      placement: "top",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          field.description
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                      ])
                                    : _vm._e(),
                                  _c("CustomField", {
                                    attrs: {
                                      componentId: _vm.componentId,
                                      field: field,
                                      disabled:
                                        _vm.isReadOnly ||
                                        (_vm.exists &&
                                          !_vm.canEdit(_vm.permissionName, [
                                            field.name,
                                          ])),
                                    },
                                    model: {
                                      value: _vm.folder[field.name],
                                      callback: function ($$v) {
                                        _vm.$set(_vm.folder, field.name, $$v)
                                      },
                                      expression: "folder[field.name]",
                                    },
                                  }),
                                ],
                                2
                              ),
                            ],
                            1
                          )
                        }
                      )
                    : _vm._e(),
                  _vm.isTagVisible
                    ? _c(
                        "b-col",
                        { staticClass: "pr-0", attrs: { cols: "12", md: "8" } },
                        [
                          _c(
                            "b-form-group",
                            [
                              _c("TagList", {
                                attrs: {
                                  holderId: _vm.id,
                                  tags: _vm.tags,
                                  readOnly: _vm.isTagReadOnly,
                                },
                                on: { modified: _vm.tagsModified },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.customFieldMap["tags"] != null
                    ? _vm._l(
                        _vm.customFieldMap["tags"],
                        function (field, index) {
                          return _c(
                            "b-col",
                            {
                              key: "tags" + index,
                              staticClass: "pr-0",
                              attrs: { cols: "12" },
                            },
                            [
                              _c(
                                "b-form-group",
                                [
                                  field.type !== "Boolean"
                                    ? _c("template", { slot: "label" }, [
                                        _c("span", { staticClass: "mr-2" }, [
                                          _vm._v(_vm._s(field.displayName)),
                                        ]),
                                        field.description
                                          ? _c(
                                              "span",
                                              [
                                                _c("font-awesome-icon", {
                                                  style: {
                                                    color:
                                                      "var(--form-control-placeholder)",
                                                    fontSize: "0.9em",
                                                  },
                                                  attrs: {
                                                    id: `${_vm.componentId}_${field.name}`,
                                                    icon: [
                                                      "far",
                                                      "circle-question",
                                                    ],
                                                  },
                                                }),
                                                _c(
                                                  "b-popover",
                                                  {
                                                    attrs: {
                                                      target: `${_vm.componentId}_${field.name}`,
                                                      triggers: "hover",
                                                      placement: "top",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          field.description
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                      ])
                                    : _vm._e(),
                                  _c("CustomField", {
                                    attrs: {
                                      componentId: _vm.componentId,
                                      field: field,
                                      disabled:
                                        _vm.isReadOnly ||
                                        (_vm.exists &&
                                          !_vm.canEdit(_vm.permissionName, [
                                            field.name,
                                          ])),
                                    },
                                    model: {
                                      value: _vm.folder[field.name],
                                      callback: function ($$v) {
                                        _vm.$set(_vm.folder, field.name, $$v)
                                      },
                                      expression: "folder[field.name]",
                                    },
                                  }),
                                ],
                                2
                              ),
                            ],
                            1
                          )
                        }
                      )
                    : _vm._e(),
                  _vm.isColorVisible
                    ? _c(
                        "b-col",
                        { staticClass: "pr-0", attrs: { cols: "12", md: "4" } },
                        [
                          _c(
                            "div",
                            { staticClass: "color-container" },
                            [
                              _c("Color", {
                                attrs: {
                                  disabled: _vm.isColorReadOnly,
                                  update: _vm.updatedColor,
                                },
                                model: {
                                  value: _vm.folder.color,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.folder, "color", $$v)
                                  },
                                  expression: "folder.color",
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm.customFieldMap["color"] != null
                    ? _vm._l(
                        _vm.customFieldMap["color"],
                        function (field, index) {
                          return _c(
                            "b-col",
                            {
                              key: "color" + index,
                              staticClass: "pr-0",
                              attrs: { cols: "12" },
                            },
                            [
                              _c(
                                "b-form-group",
                                [
                                  field.type !== "Boolean"
                                    ? _c("template", { slot: "label" }, [
                                        _c("span", { staticClass: "mr-2" }, [
                                          _vm._v(_vm._s(field.displayName)),
                                        ]),
                                        field.description
                                          ? _c(
                                              "span",
                                              [
                                                _c("font-awesome-icon", {
                                                  style: {
                                                    color:
                                                      "var(--form-control-placeholder)",
                                                    fontSize: "0.9em",
                                                  },
                                                  attrs: {
                                                    id: `${_vm.componentId}_${field.name}`,
                                                    icon: [
                                                      "far",
                                                      "circle-question",
                                                    ],
                                                  },
                                                }),
                                                _c(
                                                  "b-popover",
                                                  {
                                                    attrs: {
                                                      target: `${_vm.componentId}_${field.name}`,
                                                      triggers: "hover",
                                                      placement: "top",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          field.description
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                      ])
                                    : _vm._e(),
                                  _c("CustomField", {
                                    attrs: {
                                      componentId: _vm.componentId,
                                      field: field,
                                      disabled:
                                        _vm.isReadOnly ||
                                        (_vm.exists &&
                                          !_vm.canEdit(_vm.permissionName, [
                                            field.name,
                                          ])),
                                    },
                                    model: {
                                      value: _vm.folder[field.name],
                                      callback: function ($$v) {
                                        _vm.$set(_vm.folder, field.name, $$v)
                                      },
                                      expression: "folder[field.name]",
                                    },
                                  }),
                                ],
                                2
                              ),
                            ],
                            1
                          )
                        }
                      )
                    : _vm._e(),
                ],
                2
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("FolderSelectorModal", {
        attrs: { currentPath: _vm.path, show: _vm.folderSelectorShow },
        on: {
          "update:show": function ($event) {
            _vm.folderSelectorShow = $event
          },
          success: _vm.folderSelectorSuccess,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }